*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
//8, 16, 24, 32, 40, 48, 56, 64, etc
html,
body {
  width: 100%;
  height: 100%;
}
html {
  background-color: #282b33;
  font-style: normal;
  font-family: Nunito;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color:#C6CBD4;
}

h1{
  font-weight: 600;
  font-size: 24px;
  line-height: 121%;
  color: #FFFFFF;
  padding-bottom: 24px;
  margin:0;
}
h2{
  padding-bottom: 16px;
font-weight: 600;
font-size: 16px;
line-height: 123.4%;
}
p{
padding: 0;
margin:0;
}
