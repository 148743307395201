/* note padding 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96 */
.app {
  padding-top: 48px;
  background-color: #282b33;
  background: linear-gradient(129deg, #282b33 13.99%, #16181c 58.69%);
  background-repeat: no-repeat;
}
.container {
  padding: 0 26px;
}

.logo-big{
display:none;
}
header {
  .logo {
    padding-bottom: 56px;
    svg{
      width:215px; height:71px;
    }
  }
}
.join {
  .subscribe {
    padding-top: 60px;
  }
  input {
    margin-bottom: 12px;
  }
  input,
  button {
    width: 100%;
  }

  .formGroup {
    padding-bottom: 16px;
  }
  input {
    height: 54px;
    padding-right: 103px;
    border: 1px solid #505765;
    box-sizing: border-box;
    border-radius: 4px;
    color: #505765;
  }
  button {
    height: 54px;
    background: #a259ff;
    border-radius: 4px;
    color: #ffffff;
    border: 0;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 123.4%;
  }
  .hint {
    font-weight: 600;
    font-size: 12px;
    line-height: 123.4%;

    color: #c6cbd4;
  }
}

.already-member {
  text-align: center;
  margin: 48px 0 75px;
  padding: 30px 0 25px;
  border-top: 1px solid #505765;
  border-bottom: 1px solid #505765;
  a,
  button {
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 38px;
    border: 1px solid #505765;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 123.4%;
    color: #e1e1e1;
    background-color: transparent;
  }
}
.feature {
  article {
    padding-bottom: 70px;
  }
}

.who {
  background: #21242a;
  padding: 70px 0;

  .peoples {
    padding-top: 50px;
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 123.4%;
    }
  }
  article {
    padding-left: 112px;
    position: relative;
    h1 {
      padding-bottom: 8px;
    }
    &:first-child {
      margin-bottom: 37px;
    }
  }
  .picture {
    position: absolute;
    top: 0;
    left: -12px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 120px;
    height: 120px;
  }
  .aicha {
    .picture {
      background-image: url("./assets/aicha_small.png");
    }
  }
  .joss {
    .picture {
      background-image: url("./assets/joss_small.png");
    }
  }
  .socials {
    padding-top: 16px;
    display: flex;
    a {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      margin-right: 16px;
      display: block;
      &.linkedin {
        background-image: url("./assets/social_linkedin.png");
      }
      &.dribbble {
        background-image: url("./assets/social_dribbble.png");
      }
    }
  }
}

footer {
  font-weight: normal;
  font-size: 14px;
  line-height: 123.4%;
  text-align: center;
  padding: 70px 0;
  .logo-small {
    padding-bottom: 40px;
  }
  a {
    color: #c6cbd4;
  }
}

@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container {
    margin: 0 auto;
    max-width: 872px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .app {
    padding-top: 96px;
  }
  .logo-big{
    display: block;
    svg{
      width: 494px;
      height: 973px;
    }
    position: absolute;
    top: -158px;
    right: -70px;
  }
  header {
    .logo {
      padding-bottom: 96px;
      svg{
        width: 392px;
        height: 127px;
      }
    }
  }
  .join{
    max-width: 500px;
  }
  .feature{
    display:flex;
    justify-content: space-between;
    article{
      max-width: 350px;
    }
  }
  .already-member{
    padding: 48px 0;
  }
  .who{
    padding: 96px 0;
    position: relative;
    p{max-width: 350px;}
    .container{
      position:relative;
    }
    .peoples{
      article{
        padding: 0;
        h1{
          font-size: 20px;
          line-height: 123.4%;
        }
      }
      .picture{
        display:none;
      }
    }
    .face{
      position: absolute;
      &.aicha{
        width: 328px;
        height: 331px;
        right: 160px;
        top: -50px;
        background-image: url('./assets/aicha.png');
      }
      &.josselin{
        width: 348px;
        height: 348px;
        right: 0px;
        top: 20px;
        background-image: url('./assets/josselin.png');
      }
    }
  }
  footer{
    padding: 96px 0;
    display:flex;
    justify-content: space-between;
    .logo-small{
      padding: 0;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}


// .container {
//   max-width: 1440px;
//   margin: 0 auto;
// }
// .logo {
//   width: 392px;
//   height: 127px;
//   background-image: url('./assets/logo.png');
//   background-size: 100% auto;
// }
// .join {
//   padding-top: 100px;
//   max-width: 420px;
//   .description {
//     color: #c6cbd4;
//     padding-top: 25px;
//     font-weight: 300;
//     font-size: 16px;
//     line-height: 22px;
//     letter-spacing: 0.04em;
//   }
// }

// .subscribe {
//   padding-top: 60px;

//
//   .formGroup {
//     max-width: 500px;
//     position: relative;
//   }
//   input {
//     width: 495px;
//     height: 54px;
//     left: 283px;
//     top: 633px;
//     width: 100%;
//     padding-right: 103px;
//     border: 1px solid #505765;
//     box-sizing: border-box;
//     border-radius: 4px;
//     color: #505765;
//   }
//   button {
//     width: 103px;
//     height: 54px;
//     background: #a259ff;
//     border-radius: 4px;
//     color: #ffffff;
//     border: 0;

//     font-style: normal;
//     font-weight: bold;
//     font-size: 16px;
//     line-height: 123.4%;
//     position: absolute;
//     top: 0;
//     right: 0;
//   }

// }

// .already-member {
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 123.4%;
//   padding: 50px 0;
//   margin-top: 130px;

//   color: #e1e1e1;
//   button {
//     width: 165px;
//     height: 38px;
//     left: 508px;
//     top: 898px;

//     border: 1px solid #505765;
//     box-sizing: border-box;
//     border-radius: 4px;

//     font-weight: 600;
//     font-size: 16px;
//     line-height: 123.4%;

//     color: #e1e1e1;
//     background-color: transparent;
//     margin-left: 40px;
//   }
// }

// .feature {
//   display: flex;
//   padding-top: 90px;
//   article {
//     width: 350px;
//   }
//   h3 {
//     font-weight: 600;
//     font-size: 32px;
//     line-height: 121%;

//     color: #ffffff;
//   }
//   p {
//     font-weight: 300;
//     font-size: 16px;
//     line-height: 22px;
//     letter-spacing: 0.04em;

//     color: #c6cbd4;
//   }
// }

// .who {
//   background: #21242a;
//   padding: 100px 0;
//   .container{
//     position: relative;
//   }
//   h3 {
//     font-style: normal;
//     font-weight: 600;
//     font-size: 32px;
//     line-height: 123.4%;
//     padding-bottom: 20px;

//     color: #ffffff;
//   }
//   p {
//     max-width: 318px;
//     font-weight: 300;
//     font-size: 16px;
//     line-height: 22px;
//     letter-spacing: 0.04em;

//     color: #c6cbd4;
//   }
//   .face{
//     position: absolute;
//     &.aicha{
//       width: 328px;
//       height: 331px;
//       right: 170px;
//       top: -120px;
//       background-image: url('./assets/aicha.png');
//     }
//     &.josselin{
//       width: 348px;
//       height: 348px;
//       right: 10px;
//       top: -90px;
//       background-image: url('./assets/josselin.png');
//     }
//   }
//   .peoples{
//     display: flex;
//     article{margin-right: 80px;}
//   }
//   .name{
//     padding-bottom:8px;
//     font-weight: 600;
//       font-size: 20px;
//       line-height: 123.4%;
//       color: #FFFFFF;
//   }
//   .description{
//     padding-bottom:14px;

//     font-style: normal;
// font-weight: normal;
// font-size: 14px;
// line-height: 123.4%;

// color: #C6CBD4;
//   }
//   .socials{
//     display: flex;
//     a{
//       width:16px;
//       height:16px;
// background-size: 16px 16px;
// margin-right: 16px;
// display:block;
//       &.linkedin{
//      background-image: url('./assets/social_linkedin.png');

//       }
//       &.dribbble{
//         background-image: url('./assets/social_dribbble.png');

//           }
//     }
//   }
// }
// footer {
//   display: flex;
//   padding: 160px 0 112px;
//   justify-content: space-between;
//   .small-logo {
//     width: 27px;
//     height: 53px;
//     background-image: url('./assets/smalllogo.png');
//     background-size: 100% auto;
//     background-repeat: no-repeat;
//   }
//   .copyright {
//     line-height: 53px;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 14px;
//     line-height: 53px;
//     color: #c6cbd4;
//     a {
//       color: #c6cbd4;
//     }
//   }
// }
